export const report = {
  getAllReports: {
    url: "getAllReports",
    method: "GET",
    params: null,
  },
  updateReportStatus: {
    url: "updateReportStatus",
    method: "POST",
    data: null,
  },
};
