<template>
  <div class="mt-4">
    <div v-if="loading" style="text-align: center; margin-top: 28px">
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
    <div v-else>
      <div v-if="data.length > 0">
        <b-table striped hover :items="data" :fields="fields">
          <template v-slot:cell(updatedAt)="row">
            {{ moment(row.item.updatedAt).format("LLL") }}
          </template>
          <template v-slot:cell(reason)="row">
            {{ row.item.reported_users.reason }}
          </template>

          <template v-slot:cell(user)="row">
            <strong> {{ row.item.reported_users.user_id.fullname }}</strong>
            <div
              style="
                background-color: antiquewhite;
                border-radius: 8px;
                padding: 4px 8px;
              "
            >
              {{ row.item.reported_users.user_id.email || "Not provided!"
              }}<br />
              {{ row.item.reported_users.user_id.phone_no }}
            </div>
          </template>
          <template v-slot:cell(reportedBy)="row">
            <strong> {{ row.item.reported_users.reported_by.fullname }}</strong>
            <div
              style="
                background-color: antiquewhite;
                border-radius: 8px;
                padding: 4px 8px;
              "
            >
              {{ row.item.reported_users.reported_by.email || "Not provided!"
              }}<br />
              {{
                row.item.reported_users.reported_by.phone_no || "Not provided!"
              }}
            </div>
          </template>
          <template v-slot:cell(action)="row">
            <b-button
              v-if="status == 'false'"
              @click="closeReportedUser(row.item)"
            >
              Close
            </b-button>
            <b-button v-else @click="openReportedUser(row.item)">
              {{ row.item.reported_users.status == 0 ? "Flag" : "Close" }}
            </b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination
            :value="page"
            @change="pageChange"
            :total-rows="rows"
            :per-page="perPage"
            pills
          ></b-pagination>
        </div>
      </div>
      <div v-else class="text-center py-4">No Data</div>
    </div>
  </div>
</template>

<script>
import { report } from "@/config/api/report";

export default {
  props: ["status", "type"],
  created() {
    this.loadData();
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      data: [],
      rows: 0,
      loading: false,
      selectedSupport: null,
    };
  },
  computed: {
    fields() {
      return ["user", "reason", "reportedBy", "updatedAt", "action"];
    },
  },
  watch: {
    type() {
      this.loadData();
    },
  },
  methods: {
    loadData() {
      const api = report.getAllReports;
      api.params = {
        page: this.page,
        perPage: this.perPage,
        status: this.status,
        type: this.type,
      };
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          console.log("This is Data:", res.data);
          this.data = res.data.docs;
          this.rows = res.data.totalDocs;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pageChange(val) {
      this.page = val;
      this.loadData();
    },
    closeReportedUser(item) {
      const api = report.updateStatus;
      api.data = {
        id: item._id,
        status: parseInt(1),
        type: "user",
      };

      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to close the moderation ticket",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.generateAPI(api).then(() => {
              this.page = 1;
              this.loadData();
            });
          }
        });
    },
    openReportedUser(item) {
      var statustoUpdate = 0;
      var alertMessage = "Are you sure you want to unflag this user?";
      if (item.reported_users.status == 0) {
        statustoUpdate = 1;
        alertMessage = "Are you sure you want to flag this user?";
      }

      const api = report.updateReportStatus;
      api.data = {
        id: item._id,
        status: statustoUpdate,
        type: "user",
      };
      if (statustoUpdate == 0) {
        api.data.delete = 1;
      }
      this.$bvModal
        .msgBoxConfirm(alertMessage, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.generateAPI(api).then(() => {
              this.page = 1;
              this.loadData();
            });
          }
        });
    },
  },
};
</script>

<style>
.moderation-img {
  width: 180px;
  border-radius: 4px;
}
</style>
