<template>
  <div class="mt-4">
    <div v-if="loading" style="text-align: center; margin-top: 28px">
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
    <div v-else>
      <div v-if="data.length > 0">
        <b-table striped hover :items="data" :fields="fields">
          <template v-slot:cell(updatedAt)="row">
            {{ moment(row.item.updatedAt).format("LLL") }}
          </template>
          <template v-slot:cell(reason)="row">
            {{ row.item.reported_posts.reason }}
          </template>
          <template v-slot:cell(post)="row">
            <div v-if="row.item.reported_posts.post_id.media_files[0]">
              <div v-if="row.item.reported_posts.post_id.file_type === 'image'">
                <a
                  :href="
                    row.item.reported_posts.post_id.media_files[0].location
                  "
                  target="_blank"
                >
                  <img
                    :src="
                      row.item.reported_posts.post_id.media_files[0].location
                    "
                    height="80em"
                    alt="Post  image"
                    class="moderation-img"
                  />
                </a>
              </div>
            </div>
            <div v-else>
              <a
                :href="row.item.reported_posts.post_id.media_files[0].location"
                target="_blank"
              >
                <video
                  width="100%"
                  height="240px"
                  style="border-radius: 4px"
                  controls
                >
                  <source
                    :src="
                      row.item.reported_posts.post_id.media_files[0].location
                    "
                    type="video/mp4"
                  />
                </video>
              </a>
            </div>
          </template>
          <template v-slot:cell(reportedBy)="row">
            {{ row.item.reported_posts.reported_by.fullname }} <br />
            {{ row.item.reported_posts.reported_by.email }}
          </template>
          <template v-slot:cell(postedBy)="row">
            {{ row.item.reported_posts.post_id.user_id.fullname }} <br />
            {{ row.item.reported_posts.post_id.user_id.email }}
          </template>
          <template v-slot:cell(action)="row">
            <div v-if="status != 2">
              <b-button @click="flagPost(row.item, status)">
                <p>
                  {{ status == 0 ? "Flag Post" : "Delete Post" }}
                </p>
              </b-button>
            </div>
          </template>

          <template v-slot:cell(action)="row">
            <b-button
              v-if="status == 'false'"
              @click="closeReportedPost(row.item)"
            >
              Close
            </b-button>
            <b-button
              v-else
              @click="openReportedPost(row.item)"
              :class="{ 'btn-pink': isClicked }"
            >
              Open
            </b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination
            :value="page"
            @change="pageChange"
            :total-rows="rows"
            :per-page="perPage"
            pills
          ></b-pagination>
        </div>
      </div>
      <div v-else class="text-center py-4">No Data</div>
    </div>
  </div>
</template>

<script>
import { report } from "@/config/api/report";

export default {
  props: ["status", "type"],
  created() {
    this.loadData();
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      data: [],
      rows: 0,
      currentTab: 0,
      loading: false,
      selectedSupport: null,
      isClicked: false,
    };
  },
  computed: {
    fields() {
      return [
        "post",
        "postedBy",
        "reason",
        "reportedBy",
        "updatedAt",
        "action",
      ];
    },
  },
  watch: {
    type() {
      this.loadData();
    },
  },
  methods: {
    flagPost(post, status) {
      var statustoUpdate = parseInt(status) + 1;
      const api = report.updateReportStatus;
      api.data = {
        id: post._id,

        status: statustoUpdate,
        type: this.type == "posts" ? "post" : "user",
      };
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          console.log("This is Data:", res.data);
          this.data = res.data.docs.filter((item) => {
            return item.reported_posts.post_id != null;
          });

          this.rows = res.data.totalDocs;
        })
        .finally(() => {
          this.$bvToast.toast("The post has been updated.", {
            title: "Authentication",
            variant: "success",
            solid: true,
          });
          //Adding this because the data was not updating on tab change
          // this.$router.go();
          this.loadData();
        });
    },
    loadData() {
      const api = report.getAllReports;
      api.params = {
        page: this.page,
        perPage: this.perPage,
        status: parseInt(this.status),
        type: this.type,
      };
      this.generateAPI(api)
        .then((res) => {
          console.log("This is Data:", res.data);
          this.data = res.data.docs.filter((item) => {
            return item.reported_posts.post_id != null;
          });

          this.rows = res.data.totalDocs;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pageChange(val) {
      this.page = val;
      this.loadData();
    },
    closeReportedPost(item) {
      const api = report.updateStatus;
      api.data = {
        id: item._id,
        status: parseInt(1),
        type: "post",
      };
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to close the moderation ticket",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.generateAPI(api).then(() => {
              this.page = 1;
              this.loadData();
            });
          }
        });
    },
    openReportedPost(item) {
      this.isClicked = true;
      console.log(item._id, "item._id");
      const api = {
        ...report.updateStatus, // Spread the properties of report.updateStatus
        data: {
          id: item._id,
          status: "0",
          type: "post",
        },
      };
      // const api = report.updateStatus;
      // api.data = {
      //   id: item._id,
      //   status: "0",
      //   type: "post",
      // };
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to open the moderation ticket",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.generateAPI(api).then(() => {
              this.page = 1;
              this.loadData();
            });
          }
        });
    },
  },
};
</script>

<style>
.moderation-img {
  max-width: 180px;
  border-radius: 4px;
}
</style>
