<template>
  <div>
    <b-tabs>
      <b-tab title="Pending" active>
        <div v-if="type === 'posts'">
          <report-table-post :type="type" status="0" />
        </div>
        <div v-else>
          <report-table-user :type="type" status="0" />
        </div>
      </b-tab>
      <b-tab title="Flagged">
        <div v-if="type === 'posts'">
          <report-table-post :type="type" status="1" />
        </div>
        <div v-else>
          <report-table-user :type="type" status="1" />
        </div>
      </b-tab>
      <b-tab title="Deleted" v-if="type === 'posts'">
        <div v-if="type === 'posts'">
          <report-table-post :type="type" status="2" />
        </div>
        <div v-else>
          <report-table-user :type="type" status="2" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import reportTablePost from "./report-table-post.vue";
import reportTableUser from "./report-table-user.vue";
export default {
  props: ["type"],
  data() {
    return {
      currentTab: 0,
    };
  },
  components: {
    reportTablePost,
    reportTableUser,
  },
  methods: {
    tabChanged(val) {
      this.currentTab = val;
    },
  },
};
</script>
